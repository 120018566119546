import React, { FC, useEffect, useRef, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SignUpWidget from "../Signup/SignUpWidget";
// import CartLoginWrapper from "./CartLoginWrapper";
import StripeLayout, { StripeLayoutRef } from "../../Common/Stripe/StripeLayout";
import PhoneInput from "react-phone-input-2";
// import { PlaceOrderPayload } from "../../Interfaces/PayloadsAndResponses/Orders";
import { ItemDataType, OrderType, UpdateCartOrderPayload } from '../../Interfaces/PayloadsAndResponses/Cart';
import isEmpty from '../../Utilities/is_empty';
import { useSelector, useDispatch } from "react-redux";
import { ReduxRootState, ServerCartData } from "../../Interfaces/ReduxInterface/ReduxRootState";
import { CartDetails as CartDetailsType, CartSummary } from "../../Interfaces/PayloadsAndResponses/Cart";
// import { DeliveryFeePayload } from '../../Interfaces/PayloadsAndResponses/Cart';
import dayjs from 'dayjs';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { updateCart, updateCartOrder } from '../../Actions/cartActions';
import ReactTooltip from "react-tooltip";
import { addressValidationFromGoogleValidationAPI, getZonedTime } from '../../Utilities/functions';
import { User } from "../../Interfaces/PayloadsAndResponses/Auth";
import { PhoneFormatter } from "../../Utilities/PhoneFormatter";
import Modal from 'react-modal';
import Skeleton from "react-loading-skeleton";
import { AddressDataType } from "../../Interfaces/PayloadsAndResponses/Address";
import Login from "../Login/Login";
import { getBillingDetails } from "../../Actions/profileActions";
import DeliveryAddressPopUp from "../../Common/Popups/DeliveryAddressPopUp";
// import AddLocationForm from "../AccountDetail/Forms/AddLocationForm";
import MarketPlaceDeliveryAddressPublicPopUp from "../../Common/Popups/MarketPlaceDeliverAddressPublic";
import { SAVE_CART_ORDER_PAYLOAD } from "../../Actions/types";
import store from "../../store";
// import abcd from '../../../public/imgs/SVGs/warning-sign-9772.svg'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '600px',
    padding: '0',
  }
};

const deletePopUpStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius:'10px',
    transform: 'translate(-50%, -50%)',
    // width: '90vw',
    maxWidth: '600px',
    padding: '0',
  }
};

type CartDetailsProps = {
  isOpenSidebar: boolean;
  closeSidebar: () => void;
  fromMarketPlace?: boolean;
  reloadIfCartChanged?: () => void;
  onItemAddedToCart?: (itemImage: string | null, itemName: string) => void;
  cartPage?: string;
  continueOrdering?: () => void;
  addressValidationCheck?: boolean;
}

enum OPERATION {
  INCREMENT = 'increment',
  DECREMENT = 'decrement',
  DELETE = 'delete'
}

type TipSelectionType = {
  value: number | string;
  label: string;
}

const TipSelection: TipSelectionType[] = [
  { value: 5, label: '5%' },
  { value: 10, label: '10%' },
  { value: 15, label: '15%' },
  { value: 20, label: '20%' },
  { value: 'other', label: 'Other' }

]

const ServerCartDetails: FC<CartDetailsProps> = ({ closeSidebar, reloadIfCartChanged, fromMarketPlace,  cartPage, continueOrdering, addressValidationCheck }) => {
  //Server cart
  const serverCartData: ServerCartData = useSelector((state: ReduxRootState) => state?.server_cart?.cart_at_login);
  // const cartAddressIds = useSelector((state: ReduxRootState) => state?.server_cart?.address_ids);
  const continueOrderingForMarketplaceCart: boolean = useSelector((state: ReduxRootState) => state?.server_cart?.continue_ordering_for_same_cart);
  // const addressIdForDeliveryAt = useSelector((state: ReduxRootState) => state?.addresses?.public_addresses);
  // const addressIdForDeliveryAt = useSelector((state: ReduxRootState) => state?.addresses?.selectedAddressId);
  const [cartItems, setCartItems] = useState<ItemDataType[]>([]);
  const [cartSummary, setCartSummary] = useState<CartSummary>({} as CartSummary);
  // eslint-disable-next-line
  const [cartDetails, setCartDetails] =useState<CartDetailsType>({} as CartDetailsType);
  // const [cartIsChanged, setCartIsChanged] = useState<boolean>(false) 

  const { date }: any = useParams();
  const dispatch: any = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // useEffect(() => {
  //   dispatch(getCartAfterLogin(date.toISOString()))
  // })
  const cartChanged = () => {
    closeSidebar?.()
    // if (cartIsChanged) {
    //   reloadIfCartChanged?.()
    // }
  }

  useEffect(() => {
    let items = [] as ItemDataType[];
    let serverItemObj = {} as ItemDataType;
    let serverSummaryObj = {} as CartSummary;
    let serverDetailsObj = {} as CartDetailsType;
    if(!isEmpty(serverCartData)) {  
      serverDetailsObj = {
        name: serverCartData.runningmenu_name,
        itemCount: serverCartData.orders_quantity,
        cuttoff: serverCartData.cutoff_at,
        deliveryDate: serverCartData.delivery_at ?? `${dayjs(date).format('YYYY-MM-DD')}T${dayjs(date).format('HH:mm')}`,
        slug: serverCartData.slug,
        id: serverCartData.id,
        pending_cart_exist: serverCartData.pending_cart_exist,
        deliveryAddress: {
          formatted_address: serverCartData.formatted_address
        } as AddressDataType,
        status: serverCartData.status,
        time_zone: serverCartData?.time_zone
        // `${dayjs(date).format('YYYY-MM-DD')}T${dayjs(date).format('HH:mm')}`
      }
      
      serverSummaryObj = {
        itemsSubtotal: +serverCartData.items_subtotal,
        salesTax: +serverCartData.sales_tax,
        deliveryFee: +serverCartData.delivery_cost,
        cardProcessingFee: +serverCartData.cc_processing_fee,
        driverTip: +serverCartData.driver_tip,
        driverTipPercent: +serverCartData.driver_tip_percentage,
        total: serverCartData.total
      }
  
      serverCartData.orders?.forEach((item) => {
        serverItemObj = {
          itemID: item.fooditem_id,
          itemName: item.fooditem_name,
          itemImage: item.fooditem_image.thumb.url,
          itemDescription: '',
          itemOptionsSet: [],
          itemPrice: +item.total_price,
          itemPriceWithOptions: +item.total_price,
          itemAddress: '',
          itemSalesTaxPercentage: 0,
          quantity: item.quantity,
          optionsets_orders_attributes: [],
          remarks: '',
          orderId: item.id,
          closed_restaurant: item.closed_restaurant,
          status: item.status
        }
        items.push(serverItemObj);
      })
  
      setCartItems(items);
      setCartSummary(serverSummaryObj);
      setCartDetails(serverDetailsObj);
    }
    // cartPage && cartNavigationHandler(cartPage)
  // eslint-disable-next-line
  }, [serverCartData])

  useEffect(() => {

    // cartPage && cartNavigationHandler(cartPage)
    // eslint-disable-next-line
  }, [])

  // // const tempCard = useSelector((state: ReduxRootState) => state.profile);
  const { i18n } = useSelector((state: ReduxRootState) => state.i18n);
  const timeZone = JSON.parse(localStorage.getItem('address') || '{}')?.timezone;

  // // const user = JSON.parse(localStorage.getItem('user') || '{}') as User;

  const [cartState, setCartState] = useState<any>('cart')
  // const [cartCreditCard, setCartCreditCard] = useState<any>()
  // const { company_detail_for_order_purpose} = useSelector((state: ReduxRootState) => state.company_data_reducer);
  const { cart_at_login } = useSelector(((state: ReduxRootState) => state.server_cart));

  // credit card response now in billing api for admins
  // const cardDetails = useSelector((state: ReduxRootState) => state.profile.profile?.card_details);
  const cardDetailsFromCompanyBillings = useSelector((state: ReduxRootState) => state.payment_saved?.billing_details?.billing_cards)
  const [expanded, setExpanded] = useState<boolean>(true);
  const user = JSON.parse(localStorage.getItem('user') || '{}') as User;
  // // stripePaymentRef = createRef<StripeLayoutRef>().current;
  const stripePaymentRef = useRef<StripeLayoutRef>().current;
  // // const stripePaymentRef1 = useRef<StripeLayoutRef>().current;
  const { handleFormSubmit } = stripePaymentRef || {};
  // // const [cartStripeToken, setCartStripeToken] = useState('')
  const [cartStripeTokenLocal, setCartStripeTokenLocal] = useState('')
  const [placeOrderLoading, setPlaceOrderLoading] = useState<boolean>(false)
  const [alteringCart, setAlteringCart] = useState<any>(false)
  const [noPaymentOrderPlaced, setNoPaymentOrderPlaced] = useState<boolean>(false)
  const [billingInfo, setBillingInfo] = useState<boolean>(false)
  const [toggleAddressPopUp, setToggleAddressPopUp] = useState<boolean>(false)
  const [wrongDeliveryAddress, setWrongDeliveryAddress]= useState<boolean>(addressValidationCheck ?? false)


  const closeAddressPopUp = () => {
    setToggleAddressPopUp(false)
  }
  const openAddressTogglePopUp = () => {
    setToggleAddressPopUp(true)
  }

  const setNewAddressInCart = () => {

    // const orderDataWithTipPercent: PlaceCartOrderPayload = {
    //   runningmenu: {
    //     runningmenu_name: cartDetails.name ?? '', 
    //     delivery_at: cartDetails.deliveryDate, //"2023-01-15T11:45",
    //     // driver_tip: cartSummary.driverTip ?? 0,
    //     driver_tip_percentage: cartSummary.driverTipPercent ?? 0,
    //     orders_attributes: orderAttributes,
    //     address_ids: reduxCartAddressIds,
    //     remove_previous_cart: false
    //   }
    // }

    // let orderData: PlaceCartOrderPayload

    // if (cartSummary.driverTipPercent) {
    //   let orderDataWithAddressId: any = {}
    //   if (loggedInUser.phone_number) {
    //     orderDataWithAddressId = { ...orderDataWithTipPercent, address_id: addressIdForDeliveryAt ?? '' }
    //   } else {
    //     orderDataWithAddressId = { ...orderDataWithTipPercent, company: { addresses_active_attributes: [cartDetails?.deliveryAddress] } }
    //     // orderDataWithAddressId = orderDataWithTipPercent
    //   }
    // company: { addresses_active_attributes: [cartDetails?.deliveryAddress] }
    const orderData:ServerCartData = cart_at_login
    let newAddressData:ServerCartData = {
      ...orderData,
       address_line: '47 model town lahore,pakistan',
      formatted_address: '47 model town',
      time_zone: 'Africa'
    } 
    // store
    store.dispatch({
      type: SAVE_CART_ORDER_PAYLOAD,
      payload: newAddressData
    })
  }

  const [company_user_credit_card_token_id] = useState(null)
  let token = ''
    if (company_user_credit_card_token_id) {
      token = company_user_credit_card_token_id as unknown as string;
      if (token){
        
      }
    } else {
      handleFormSubmit?.();
    }


  const [cartDeliveryDetails, setCartDeliveryDetails] = useState<any>({
    name: (user.first_name + ' '+ user.last_name) ?? '',
    phoneNumber: user.phone_number ?? '',
    deliveryInstructions: ''
  })

  const [defaultCreditCard, setDefaultCreditCard] = useState<any>(null)

  useEffect(() => {

    const getBillingInfoForUser = () => {
      dispatch(getBillingDetails())
      setBillingInfo(true)
    }

    if (isEmpty(cardDetailsFromCompanyBillings)) {
      user?.ss_user && !billingInfo && getBillingInfoForUser()
    }
    getDefaultCreditCard()

    // eslint-disable-next-line
  }, [cardDetailsFromCompanyBillings, billingInfo])

  const [phoneErr, setPhoneErr] = useState<any>('empty')

  const errorStatefun = () => {
      if(phoneErr === 'invalid'){
         return 'Please match the format XXX-XXX-XXXX'
      }else if(phoneErr === 'empty'){
        return 'Phone number is required'
      }else {
        return ''
      }
  }

  const getDefaultCreditCard = () => {
    const selectedCreditCard: any = cardDetailsFromCompanyBillings?.find((credit_card) => {
      return credit_card?.default_card === true
    })

    setDefaultCreditCard(selectedCreditCard?.details)
    return selectedCreditCard?.details
  }


  const cartDeliveryDetailsHandler = (e: any) => {
    setCartDeliveryDetails({
      ...cartDeliveryDetails,
      [e.target.name]: e.target.value
    })
  }

  // // const cartDeliveryDetailsPhoneHandler = (phone: any) => {
  // //   setCartDeliveryDetails({
  // //     ...cartDeliveryDetails,
  // //     phone: phone
  // //   })
  // // }
  // // const cartDisplayHandling = (page: any) => {
  
  const [tipItnput, setTipItnput] = useState<boolean>(false);
  // eslint-disable-next-line
  const [tipValue, setTipValue] = useState<number>(0);
  const [tipOtherValue, setTipOtherValue] = useState<number | undefined>();
  const [checkoutPopUp, setCheckoutPopUp] = useState<boolean>(false)
  const [deletePopUP, setDeletePopUP] = useState<boolean>(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState<number>();

  const scrollableRef = useRef<HTMLUListElement>(null);
  const [reachedEnd, setReachedEnd] = useState(false);

  const handleScroll = () => {
    const scrollableElement = scrollableRef.current;
    if (scrollableElement !== null) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableElement;
      if (scrollHeight - (scrollTop + clientHeight) <= 1) {
        setReachedEnd(true);
      } else {
        setReachedEnd(false);
      }
    }
  };

  const cartDisplayHandling = (page:any) => {
    setCartState(page)
  }

  const cartPopupDisplayHandling = () => {
    setPlaceOrderLoading(false)
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('os', 'thankyou');
    history.replace({ search: searchParams.toString() });

    setCheckoutPopUp(true)
  }

  const closeModal = () => {
    if (deletePopUP) {
      setDeletePopUP(false)
    } else {
      setCheckoutPopUp(false)
      const slugForCart = cartDetails.slug || ""
      const currentPage = window.location.pathname.split('/')
      if (isEmpty(currentPage[3])) {
        window.location.replace(`/menu/${currentPage[2]}/${slugForCart}`)
      } else {
        window.location.reload()
      }
    }
  }

  const placeOrder = () => {
    setNoPaymentOrderPlaced(false)
    if (!isEmpty(cartDeliveryDetails.name) && !isEmpty(cartDeliveryDetails.phoneNumber) && (cartDeliveryDetails.phoneNumber?.length >= 10) && (!isEmpty(defaultCreditCard) || cartStripeTokenLocal || stripeToken)) {
      const slugForCart = cartDetails.slug || ""
      const formattedPhone = PhoneFormatter(cartDeliveryDetails?.phoneNumber)
      const Checkoutpayload = {
        person_name: cartDeliveryDetails?.name,
        person_phone: formattedPhone,
        delivery_instructions: cartDeliveryDetails?.deliveryInstructions,
      }
      const stripeCheckoutPayLoad = {
        person_name: cartDeliveryDetails?.name,
        person_phone: formattedPhone,
        delivery_instructions: cartDeliveryDetails?.deliveryInstructions,
        stripe_token: cartStripeTokenLocal || stripeToken,
      }
      if (cartStripeTokenLocal || stripeToken) {
        setPlaceOrderLoading(true)
        dispatch(updateCart(stripeCheckoutPayLoad, slugForCart, cartPopupDisplayHandling))
      } else {
        setPlaceOrderLoading(true)
        dispatch(updateCart(Checkoutpayload, slugForCart, cartPopupDisplayHandling))
      }
    } else {
      setNoPaymentOrderPlaced(false)
    }
  }

  const noPreviousPaymentMethodHandler = () => {
    if (!isEmpty(cartDeliveryDetails.name) && !isEmpty(cartDeliveryDetails.phoneNumber) && (cartDeliveryDetails.phoneNumber?.length >= 10)) {
      setNoPaymentOrderPlaced(true)
    }
  }

  const stripeError = () => {
    setNoPaymentOrderPlaced(false)
  }
  // useEffect(() => {
  // },[CCinfo])

  const cartNavigationHandler = ( page: string) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}') as User;
    if (user?.email && page === 'checkout'){
      setCartState('confirm')
    }else if (user?.email && page === 'placeOrder'){
      setCartState('cart')
    }else if (page === 'checkout'){
      setCartState('signUp')
    }else if (page === 'placeOrder') {
      setCartState('login')
    }
  }
  let stripeToken = ''
  const cartStripeTokenHandler = (token: string) => {
    setCartStripeTokenLocal(token)
    stripeToken = token
    if (noPaymentOrderPlaced) {
      placeOrder()
    }
  } 
  // store.dispatch({
  //   type: SET_MENU_TAB_MEETING,
  //   payload: meeting
  // });

  const alteringController = () => {
    setAlteringCart(false)
  }

  const deleteCartItemConfirmation = (itemIndex: number) => {
    setDeleteItemIndex(itemIndex);
    setDeletePopUP(true);
  }

  //Increment & Decrement Item Count
  const changeItemCount = (itemIndex: number, operation: OPERATION) => {
    setAlteringCart(true);
    // eslint-disable-next-line
    const resultedItem = cartItems?.find((item, index) => {
      if(index === itemIndex) {
        switch (operation) {
          case OPERATION.INCREMENT:
            item.quantity++
            return item;
          case OPERATION.DECREMENT:
            item.quantity--
            return item;
          case OPERATION.DELETE:
            item.status = 'cancelled';
            return item;
          default:
            return item;
        }
      }
    });

    if(!isEmpty(resultedItem)) {
      const updateCartData:  UpdateCartOrderPayload = {
        runningmenu: {
          orders_attributes: [{
            id: resultedItem?.orderId ?? 0,
            quantity: resultedItem?.quantity ?? 0,
            status: resultedItem?.status ?? ''
          }]
        }
      }
      dispatch(updateCartOrder(updateCartData, cartDetails.slug ?? '', alteringController, cartItems))
      if(cartItems.length > 1) {
        setDeletePopUP(false);
      } else if(cartItems.length === 1) {
        if(cartItems[0]?.status === 'cancelled') {
          setDeletePopUP(false);
          closeSidebar?.()
        }
      } else {
        setDeletePopUP(false);
        closeSidebar?.()
      }
    }
    // setCartIsChanged(true)
  };

  const onTipSelect = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if(e.target.name === 'other_tip' ) {
      setTipOtherValue(+e.target.value);
      setCartSummary({...cartSummary, driverTip: +e.target.value, driverTipPercent: 0})
      
    } else {
      if(e.target.value === 'other') {
        //setCartSummary({...cartSummary, driverTip: 0, driverTipPercent: 0})

        setTipValue(0)
        setTipItnput(true);
      } else {
        setTipOtherValue(undefined)
        const tipValue: number = +((+e.target.value / 100) * (cartSummary.itemsSubtotal)).toFixed(2);
        setCartSummary({...cartSummary, driverTip: tipValue, driverTipPercent: +e.target.value})
        const updateCartData:  UpdateCartOrderPayload = {
          runningmenu: {
            id: cartDetails.id,
            driver_tip_percentage: +e.target.value
          }
        };
        dispatch(updateCartOrder(updateCartData, cartDetails.slug ?? '', alteringController))
        setTipValue(tipValue);
        setTipItnput(false);
      }
    }
  }

  const goToTipSelection = () => {
    const updateCartData:  UpdateCartOrderPayload = {
      runningmenu: {
        id: cartDetails.id,
        driver_tip: tipOtherValue
      }
    };
    dispatch(updateCartOrder(updateCartData, cartDetails.slug ?? '', alteringController))
    setTipItnput(false); 
  }

  const orderAttributes: OrderType[] = []
  let itemObj: OrderType = {} as OrderType
  cartItems?.forEach((item) => {
    itemObj = {
      quantity: item.quantity,
      fooditem_id: item.itemID,
      optionsets_orders_attributes: [],
      remarks: 'no remarks'
    }
    orderAttributes.push(itemObj)
  });

  

  const handleCartSummary = () => {
    setExpanded(!expanded);
  };

  const redirectToDelivery = () => {
    closeSidebar?.()
    history.push(`/menu/${dayjs(cartDetails?.deliveryDate).format('M-D-YYYY')}/${cartDetails?.slug}`);
  }

  useEffect(() => {
    // let addressVerification = false
  const user = JSON.parse(localStorage.getItem('user') || '{}') as User;

    let counter = 0
    // dispatch(validateCartDeliveryAddress)
    if ((cartState === 'confirm' || counter === 0) && user?.ss_user) {
      // validateAddress(cart_at_login?.formatted_address);
      const validatingCartAddress = async () => {

        const verificationResuktFromGoogle = await addressValidationFromGoogleValidationAPI(cart_at_login?.formatted_address)
        const verificationResuktFromGoogleSecondAddress = await addressValidationFromGoogleValidationAPI(cart_at_login?.address_line)

        // const apiVerdict = verificationResuktFromGoogle?.verdict
        if (verificationResuktFromGoogle?.addressComplete || verificationResuktFromGoogleSecondAddress?.addressComplete) {

          if (!addressValidationCheck) {

            setWrongDeliveryAddress(false)
          }
        } else {
          // addressVerification = false
          setWrongDeliveryAddress(true)
        }
      }
      // const data = await 
      validatingCartAddress()
      counter = 1
    }
    // eslint-disable-next-line
  }, [cartState, wrongDeliveryAddress])

  useEffect(() => {
    // let addressVerification = false
  const user = JSON.parse(localStorage.getItem('user') || '{}') as User;

    let counter = 0

    if ((cartState === 'confirm' || counter === 0) && user?.ss_user) {
      // validateAddress(cart_at_login?.formatted_address);
      const validatingCartAddress = async () => {

        // const verificationResuktFromGoogle = await addressValidationFromGoogleValidationAPI(cart_at_login?.formatted_address)
        const verificationResuktFromGoogleSecondAddress = await addressValidationFromGoogleValidationAPI(cart_at_login?.address_line)

        // const apiVerdict = verificationResuktFromGoogle?.verdict
        if (verificationResuktFromGoogleSecondAddress?.addressComplete) {

          // if (verificationResuktFromGoogle?.addressComplete ) {
          // addressVerification = true
          setWrongDeliveryAddress(false)
        } else {
          // addressVerification = false
          setWrongDeliveryAddress(true)
        }
      }
      // const data = await 
      validatingCartAddress()
      counter = 1
    }
    // eslint-disable-next-line
  }, [cartState, wrongDeliveryAddress])

  // const handleVerdict = (verdict: any) => {
  //   if (verdict) {
  //     // Example: If address is complete and validated
  //     if (verdict.addressComplete) {
  //       // console.log("Address is complete and validated");
  //       // Perform actions specific to a complete and validated address
  //     } else {
  //       setToggleAddressPopUp(true)
  //     }
  //     // Example: If address has inferred components
  //     if (verdict.hasInferredComponents) {
  //       // Perform actions specific to an address with inferred components
  //     }
  //   }
  // };
  // const validateAddress = async (AddressToBeTested: string) => {
  //   try {
  //     const response = await fetch(`https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`, {
  //       method: 'POST',
  //       // headers: {
  //       //   'Content-Type': 'application/json',
  //       //   // Add your API key here
  //       //   'Authorization': `Bearer ${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`
  //       // },
  //       body: JSON.stringify({
  //         address: {
  //           "addressLines": [AddressToBeTested]
  //         },
  //         "enableUspsCass": true,
  //         "languageOptions": {}
  //       })
  //     });
  //     const data = await response.json();
  //     // console.log('Data For Validation',data);
  //     console.log('%cFUNCTIONAL_Validation--RESULT', 'color:yellow', data);
  //     const isValidAddress = data.result.verdict
  //     handleVerdict(data.result.verdict);
  //     // setValidatedAddress(data.result);
  //   } catch (error) {
  //     console.error('Error validating address:', error);
  //   }
  // };


  
  return (
    <>
      {cartState === 'cart' && <section className="cart-wrapper">
        {/* Cart header Section */}
        <header className="d-flex justify-content-between border-bottom p-3 p-md-4 cart-header">
          <div className="d-flex aling-items-center cart-summery">
            <div className="d-flex align-items-center justify-content-center rounded-circle position-relative cart-logo">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 33" style={{ height: 32, width: 28 }}>
                <path id="bag-shopping-thin" d="M8,8V6A6,6,0,1,1,20,6V8h5a3,3,0,0,1,3,3V26a6,6,0,0,1-6,6H6a6,6,0,0,1-6-6V11A3,3,0,0,1,3,8ZM9,8H19V6A5,5,0,1,0,9,6ZM3,9a2,2,0,0,0-2,2V26a5,5,0,0,0,5,5H22a5,5,0,0,0,5-5V11a2,2,0,0,0-2-2H20v5.5a.5.5,0,1,1-1,0V9H9v5.5a.5.5,0,0,1-1,0V9Z" transform="translate(0.5 0.5)" fill="#dc3644" stroke="#dc3644" strokeWidth="1" />
              </svg>
              <div className="badge badge-danger badge-position rounded-pill position-absolute badge-position">
                {cartDetails.itemCount}
              </div>
            </div>
            <div className="cart-summary-header-title pl-2 pl-sm-3">
              <p className="fs-16 fw-600 fs-sm-12 m-0 text-gray-100">
                Your Cart from
              </p>
              <h4 className="m-0 fw-600 fs-sm-16">
                {cartDetails.name}
              </h4>
              {/* <span className="cutt-off-badge mt-1"> */}
              {(fromMarketPlace ? !continueOrderingForMarketplaceCart : true) && continueOrdering && cartDetails?.status === 'pending' && dayjs(cartDetails?.cuttoff).isAfter(new Date()) &&
                <>
                  <span className="fs-18 fw-600 text-gray-100">
                  <i className="fas fa-map-marker-alt fa-md mr-2"></i>{cartDetails.deliveryAddress?.formatted_address}
                  </span>
                  <p onClick={redirectToDelivery} className="fs-18 fw-600 text-danger m-0 d-flex align-items-center cursor-pointer">Continue Ordering <i className="fas fa-angle-right ml-2"></i></p>
                </>
              }
              {cartDetails.cuttoff &&
                <span className={`'text-nowrap ' ${dayjs(cartDetails.cuttoff).isAfter(new Date()) ? 'badge-outline-primary cursor-pointer mt-0 d-inline-block' : 'badge-outline-danger cursor-pointer mt-0 d-inline-block'}`}>
                  <span data-tip
                    data-for='menu-detail-page-cutoff'
                    // style={{ color: dayjs(currentDeliveryCutOff).isAfter(new Date()) ? '#007bff' : '#dc3545' }}
                  >
                    {i18n?.t('Cutoff ')}
                    {
                      dayjs(cartDetails.cuttoff).isAfter(new Date()) ?
                        `in ${dayjs(cartDetails.cuttoff).toNow(true)}` : `was ${dayjs(cartDetails.cuttoff).fromNow()}`
                      // dayjs(cartDetails.cuttoff).toNow(true) : dayjs(cartDetails.cuttoff).fromNow()
                    }
                  </span>
                  {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                    <ReactTooltip place='bottom' type='dark' id='menu-detail-page-cutoff' effect='solid' className='react-tooltip'>
                      <span>{getZonedTime(cartDetails.cuttoff, 'EEE. MMM dd h:mma, zzzz', timeZone)}</span>
                    </ReactTooltip>
                  }
                </span>
              }
              {/* </span> */}
            </div>
          </div>
          <div className="cart-close-btn" onClick={(() => cartChanged?.())}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ height: 32, width: 28 }} className="text-black">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </header>
        {/* cart products list */}
        <section className={`cart-summary-items-wrapper px-3 px-sm-4`}>
          <div className="cart-summary-group-item">
            <div className="cart-summary-items">
              {alteringCart ?
                <ul className="px-0 list-grpup">
                  <li>
                    <Skeleton height={60} width={410} />
                    <Skeleton height={60} width={410} />
                  </li>
                </ul>
                :
                <ul
                  ref={scrollableRef}
                  onScroll={handleScroll}
                  className='px-0 list-grpup'
                  // style={{ overflowY: 'scroll', maxHeight: 600 }}
                >
                {cartItems?.map((item: ItemDataType, index: any) =>
                  <li className={`pt-32 cart-item-smallscreen${item.closed_restaurant === true ? "disable-item" : ""}`} key={index}>
                    <div className="product-row d-flex align-items-center justify-content-between">
                      <div className="product-card d-flex">
                        <div className="product-card-img position-relative">
                          <img src={item?.itemImage ?? '/imgs/SVGs/ItemPlaceholder.svg'} alt="" className="img-fluid rounded-lg cart-item-img" />
                          {item?.quantity>1 &&<div 
                            className="badge badge-danger d-flex align-items-center justify-content-center rounded-circle card-badge-position"
                            // onClick={() => changeItemCount(index, OPERATION.DELETE)}
                            onClick={() => deleteCartItemConfirmation(index)}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" className="d-flex align-items-center justify-content-center m-auto cart-item-svg-height">
                              <path id="trash-solid" d="M4.225.553,4,1H1A1,1,0,0,0,1,3H13a1,1,0,0,0,0-2H10L9.775.553A1,1,0,0,0,8.881,0H5.119A1,1,0,0,0,4.225.553ZM13,4H1l.663,10.594A1.5,1.5,0,0,0,3.159,16h7.681a1.5,1.5,0,0,0,1.5-1.406Z" fill="#dc3644" />
                            </svg>
                          </div>}
                        </div>
                        <div className="pl-2 pl-sm-3 product-card-info">
                          <h5 className="fs-18 fs-sm-14 m-0 fw-600 text-gray-700">{item?.itemName}</h5>
                          <p className="fs-18 fs-sm-14 m-0 mt-1 fw-normal text-gray-600">{`$${item?.itemPriceWithOptions ? item?.itemPriceWithOptions : item?.itemPrice}`}</p>
                        </div>
                      </div>
                      <div className="product-card-description">
                        <div className="instant-cart-manager-container d-flex align-items-center">
                          <button disabled={item.status === "cancelled" || item.closed_restaurant === true} className="decrease-product-count" onClick={(() => item?.quantity === 1 ? deleteCartItemConfirmation(index) : changeItemCount(index, OPERATION.DECREMENT))}>
                            {item.quantity === 1 ?
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" className="d-flex align-items-center justify-content-center m-auto mt-1 svg-height">
                                <path id="trash-solid" d="M4.225.553,4,1H1A1,1,0,0,0,1,3H13a1,1,0,0,0,0-2H10L9.775.553A1,1,0,0,0,8.881,0H5.119A1,1,0,0,0,4.225.553ZM13,4H1l.663,10.594A1.5,1.5,0,0,0,3.159,16h7.681a1.5,1.5,0,0,0,1.5-1.406Z" fill="#dc3644" />
                              </svg>
                              :
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                              </svg>
                            }
                            
                          </button>
                          <span className="instant-cart-manager-quantity fs-18 fw-normal px-3">
                            {item?.quantity}
                          </span>
                          <button disabled={item.status === "cancelled" || item.closed_restaurant === true} className="increase-product-count" onClick={() => changeItemCount(index, OPERATION.INCREMENT)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    {item.closed_restaurant === true && <p className="font-italic" style={{color: "#6E6E73"}}>Item Unavailable</p> }
                  </li>
                )}
                  <li className='pt-32'></li>
                </ul>
              }
            </div>
          </div>
        </section>
        {/* Cart product Descriptions */}
        {/* / moved the pricing section from here to Place orders Page  / */}
        {false && <section className={`cart-summary-amounts-wrapper pt-3 pt-sm-4 px-0 px-sm-4 w-100 ${reachedEnd ? 'cart-summary-amounts-wrapper-withoutshadow' : 'cart-summary-amounts-wrapper'} `}>
          <Accordion expanded={expanded} onChange={handleCartSummary}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            className="cart-accordian-wrapper"
            // onClick={prepareDeliveryFeePayload(cartItems)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: 'white', backgroundColor: '#6e6e73', borderRadius: '50%' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="py-1 accordian-summary"
            >

              {/* cart-summary-total-amount*/}

            </AccordionSummary>

            <AccordionDetails className="px-3 px-sm-4 pt-3 pb-0">
              <div>
                <li className="d-flex align-items-center justify-content-between mb-2">
                  <p className="fs-18 fs-sm-14 fw-normal text-gray-600 m-0">Items Subtotal</p>
                  {/* <p className="fs-18 fw-normal text-gray-600 m-0">{`$${(+cartSummary?.itemsSubtotal ?? 0).toFixed(2)}`}</p> */}
                  <p className="fs-18 fs-sm-14 fw-normal text-gray-600 m-0">{`$${isNaN(+cartSummary?.itemsSubtotal) ? '0.00' :(+cartSummary?.itemsSubtotal ?? 0).toFixed(2)}`}</p>
                </li>
                {/* cart-summary-amounts-sales-tex-fee */}
                <li className="cart-summary-amounts-sales-tex-fee d-flex align-items-center justify-content-between mt-1">
                  <p className="cart-summary-details-text">Sales Tax</p>
                  {/* <p className="cart-summary-details-text">{`$${(+cartSummary?.salesTax ?? 0).toFixed(2)}`}</p> */}
                  <p className="cart-summary-details-text">{`$${isNaN(+cartSummary?.salesTax) ? '0.00' :(+cartSummary?.salesTax ?? 0).toFixed(2)}`}</p>
                </li>
                {/* cart-summary-amounts-delivery-fee */}
                <li className="cart-summary-amounts-delivery-fee d-flex align-items-center justify-content-between">
                  <p className="cart-summary-details-text">Delivery Fee</p>
                  {/* <p className="cart-summary-details-text">{`$${(+cartSummary?.deliveryFee ?? 0).toFixed(2)}`}</p> */}
                  <p className="cart-summary-details-text">{`$${isNaN(+cartSummary?.deliveryFee) ? '0.00' :(+cartSummary?.deliveryFee ?? 0).toFixed(2)}`}</p>
                </li>
                {/* cart-summary-card-processing-fee */}
                <li className="cart-summary-card-processing-fee d-flex align-items-center justify-content-between">
                  <p className="cart-summary-details-text">Credit Card Processing Fee</p>
                  {/* <p className="cart-summary-details-text">{`$${(+cartSummary?.cardProcessingFee ?? 0).toFixed(2)}`}</p> */}
                  <p className="cart-summary-details-text">{`$${isNaN(+cartSummary?.cardProcessingFee) ? '0.00' :(+cartSummary?.cardProcessingFee ?? 0).toFixed(2)}`}</p>
                </li>
                {/* cart-summary-tip-amount */}
                <li className="cart-summary-tip-amount d-flex align-items-center justify-content-between mt-3" style={{marginBottom:"12px"}}>
                  <p className="cart-summary-details-text">Driver Tip</p>
                  {/* <p className="cart-summary-details-text">10% ($2.5)</p> */}
                  <p className="cart-summary-details-text">
                    {/* {`${(cartSummary?.driverTipPercent && (+cartSummary?.driverTipPercent >0)) ?
                      cartSummary?.driverTipPercent+'%'
                        : ''} ($${(+cartSummary.driverTip ?? 0).toFixed(2)})
                    `} */}
                     {`${(cartSummary?.driverTipPercent && (!isEmpty(cartSummary) && cartSummary?.driverTipPercent! >0)) ?
                      cartSummary?.driverTipPercent+'%'
                        : ''} ${!isEmpty(cartSummary) && cartSummary.driverTipPercent && cartSummary.driverTipPercent! > 0 ? 'is' : ''}  $${isNaN(+cartSummary.driverTip) ? '0.00' :(+cartSummary.driverTip ?? 0).toFixed(2)}
                    `}
                     {/* {`${(cartSummary?.driverTipPercent && (cartSummary?.driverTipPercent >0)) ?
                      cartSummary?.driverTipPercent+'%'
                        : ''} ($${isNaN(+cartSummary.driverTip) ? '0.00' :(+cartSummary.driverTip ?? 0).toFixed(2)})
                    `} */}
                  </p>
                  {/* <p className="cart-summary-details-text">{`${!isEmpty(tipLabel) ? tipLabel+'%' : ''} ($${cartSummary.driverTip ?? 0})`}</p> */}
                </li>
                {/* cart-summary-tip-amount-selection */}
                {tipItnput ?
                  <li className="d-flex align-items-center justify-content-between">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text px-3" id="basic-addon1">$</span>
                      </div>
                      <input className="form-control mr-2 input-tip-calculation" type='number' placeholder="Enter tip amount" name="other_tip" value={tipOtherValue} onChange={onTipSelect}/>
                    </div>
                    <button
                      className="btn btn-danger"
                      style={{minWidth:'64px',height:'42px'}}
                      onClick={goToTipSelection}
                    >
                      Ok
                    </button>
                  </li>
                :
                  <div className="cart-summary-tip-amount-selection">
                    <select
                      multiple
                      style={{backgroundColor:'#F8F9FA'}}
                      className="form-control cart-summary-tip-selection p-0"
                      id="exampleFormControlSelect2"
                      onChange={onTipSelect}
                    >
                      {TipSelection?.map((opt, index) => <option key={index} value={opt.value} selected={cartSummary?.driverTipPercent === opt.value || (cartSummary?.driverTipPercent?.toString() === '' && opt.value === 'other')}>{opt.label}</option>)}
                    </select>
                  </div>
                }
              </div>
            </AccordionDetails>
          </Accordion>
          {/* Cart checkout button */}
        </section>
        }
        <footer className="px-0 mt-2 px-3 px-sm-0 bg-white cart-footer-alingment">
          <div
            className="cart-summary-total-amount d-flex align-items-center justify-content-betweenmy-1 w-100 my-2 px-3 px-sm-4"
          >
            <h4 className="fw-600 fs-sm-16 m-0 text-gray-700">Items Subtotal</h4>
            <h4 className="fw-600 fs-sm-16 ml-auto mr-3 mb-0 text-gray-700">{`$${isNaN(+cartSummary?.itemsSubtotal) ? '0.00' : (+cartSummary?.itemsSubtotal ?? 0).toFixed(2)}`}</h4>
          </div>
          <hr style={{width:'100%',marginTop:'2rem',marginBottom:'2rem'}} />
          <div className="px-3 px-sm-4">
          {(cartSummary?.total && cartSummary?.total <= 0) || isEmpty(cartSummary?.total) ? <button className="btn btn-block btn-danger py-3 fs-18 fw-600 checkout-btn" disabled >Checkout</button>
            :
            <button className="btn btn-block btn-danger py-3 fs-18 fw-600 checkout-btn" onClick={() => cartNavigationHandler('checkout')}>Checkout</button>
          }
          </div>
        </footer>
      </section>
      }

      {/* Second page Signup page */}
      {cartState === 'signUp' && <section className="signup-page-wrapper">
        <header className="d-flex justify-content-between border-bottom p-4 cart-header">
          <div className="d-flex aling-items-center cart-summery">
            <button className="d-flex align-items-center justify-content-center rounded-circle border-0 cart-logo" onClick={() => setCartState('cart')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="28.67" height="24.572" viewBox="0 0 28.67 24.572">
                <path id="arrow-right-regular" d="M11.738,64.437.476,75.187a1.537,1.537,0,0,0,0,2.224l11.262,10.75a1.536,1.536,0,0,0,2.12-2.224l-8.491-8.1H27.134a1.536,1.536,0,1,0,0-3.072H5.371l8.491-8.1a1.539,1.539,0,0,0-2.124-2.227Z" transform="translate(0 -64.013)" fill="#dc3644"/>
              </svg>
            </button>
            <div className="cart-summary-header-title pl-2 pl-sm-3">
              <p className="fs-16 fs-sm-12 fw-600 m-0 text-gray-100">
                To continue please
              </p>
              <h4 className="m-0 fw-600 fs-sm-16">
                Signup
              </h4>
            </div>
          </div>
          <div className="cart-close-btn">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ height: 32, width: 28 }}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </header>
        {/* Signup form section */}
        <section className="signup-form-section mt-3 px-3">
          {/* Signup form main section  */}
          {/* <h4 className="m-0">signup form here</h4> */}
          <SignUpWidget
            isCartSignUp={true}
            cartDisplayHandling={cartDisplayHandling}
            // cartOrderPayload={orderData}
            isOpenModal={false}
          />
        </section>
        {/* Cart signup page footer */}
        {/* <footer className="mt-3 px-4">
          <button className="btn btn-block btn-danger py-3 fs-18 fw-600">Sign Up</button>
          <p className="fs-18 fw-normal text-center text-gray-200 m-0 mt-3">Already have an account ? <span className="text-danger fw-600 cursor-pointer" onClick={() => setCartState('login')}>Log In</span></p>
        </footer> */}
      </section>}
      {/* Cart Signup page End here */}

      {/* login page third page*/}
      {cartState === 'login' && <section className="login-page-wrapper">
        <header className="d-flex justify-content-between border-bottom p-4 cart-header">
          <div className="d-flex aling-items-center cart-summery">
            <button className="d-flex align-items-center justify-content-center rounded-circle border-0 cart-logo" onClick={() => setCartState('signUp')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="28.67" height="24.572" viewBox="0 0 28.67 24.572">
                <path id="arrow-right-regular" d="M11.738,64.437.476,75.187a1.537,1.537,0,0,0,0,2.224l11.262,10.75a1.536,1.536,0,0,0,2.12-2.224l-8.491-8.1H27.134a1.536,1.536,0,1,0,0-3.072H5.371l8.491-8.1a1.539,1.539,0,0,0-2.124-2.227Z" transform="translate(0 -64.013)" fill="#dc3644"/>
              </svg>
            </button>
            <div className="cart-summary-header-title pl-2 pl-sm-3">
              <p className="fs-16 fs-sm-12 fw-600 m-0 text-gray-100">
                To continue please
              </p>
              <h4 className="m-0 fw-600 fs-sm-16">
                Log In
              </h4>
            </div>
          </div>
          <div className="cart-close-btn">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ height: 32, width: 28 }}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </header>
        {/* login form section */}
        <section className="login-form-section mt-3 w-100 px-4">
          {/* login form main section  */}
          {/* <h4 className="m-0">login form here</h4> */}
          {/* <CartLoginWrapper
          isCartLogin = {true}
          cartDisplayHandling={cartDisplayHandling}
          cartOrderPayload={orderData}
          loginModal={false}
          /> */}
          <Login
            isCartLogin = {true}
            cartDisplayHandling={cartDisplayHandling}
            isOpenModal={false}
          />
        </section>
        {/* Cart login page footer */}
        {/* <footer className="mt-3 px-4">
          <button className="btn btn-block btn-danger py-3 fs-18 fw-600"  onClick={() => setCartState('confirm')}>Next Step</button>
          <p className="fs-18 fw-normal text-center text-gray-200 m-0 mt-3"><span className="text-danger fw-600 cursor-pointer">proceed</span></p>
        </footer> */}
      </section>}
      {/* Cart Login page End here */}


      {/* Place order page*/}
      {cartState === 'confirm' && <section className="order-page-wrapper">
        <header className="d-flex justify-content-between border-bottom p-4 cart-header">
          <div className="d-flex aling-items-center cart-summery">
            <button className="d-flex align-items-center justify-content-center rounded-circle border-0 cart-logo" onClick={() => cartNavigationHandler('placeOrder')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="28.67" height="24.572" viewBox="0 0 28.67 24.572">
                <path id="arrow-right-regular" d="M11.738,64.437.476,75.187a1.537,1.537,0,0,0,0,2.224l11.262,10.75a1.536,1.536,0,0,0,2.12-2.224l-8.491-8.1H27.134a1.536,1.536,0,1,0,0-3.072H5.371l8.491-8.1a1.539,1.539,0,0,0-2.124-2.227Z" transform="translate(0 -64.013)" fill="#dc3644" />
              </svg>
            </button>
            <div className="cart-summary-header-title pl-2 pl-sm-3">
              <p className="fs-16 fs-sm-12 fw-600 m-0 text-gray-100">
                Confirmation of
              </p>
              <h4 className="m-0 fw-600 fs-sm-16">
                {serverCartData.runningmenu_name}
              </h4>
            </div>
          </div>
          <div className="cart-close-btn" onClick={(() => cartChanged?.())}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ height: 32, width: 28 }}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </header>
        {/* place order page Content */}
        <section className="place-order-section mt-3 px-3 px-sm-4 overflow-auto">
          <div className="cart-order-delivery-info">
            <ul className="px-0 list-grpup">
              {wrongDeliveryAddress ? <li className="  rounded" style={{ backgroundColor: '#fef1ef' }}>
                <div className="pt-3 px-2 ">
                  <div className="d-flex justify-content-center align-items-start  ">
                    {/* fa fa-exclamation-circle */}
                    <div className="pt-1 m-1 pl-1">
                      <i className='icon fas fa-exclamation-circle'></i>
                    </div>
                    <p className="m-1 pl-1">
                      Invalid address. <br /> Please be sure to select a valid & complete delivery address.
                    </p>
                    <button type="button" className="btn btn-light btn-sm rounded-pill text-nowrap px-2 font-weight-bold shadow-lg mb-2 bg-white rounded" onClick={openAddressTogglePopUp}>Edit address</button>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-start pl-1 pb-2">
                  <div className="cart-order-location d-flex align-items-center justify-content-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{ height: 24, width: 24 }}>
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                    </svg>
                  </div>
                  <div>
                    <h6 className="fs-18 fs-sm-14 fw-600 text-gray-600 m-0 pl-2 pl-sm-3">{cart_at_login?.address_line}</h6>
                  </div>
                </div>
              </li> : <li className="d-flex align-items-center justify-content-start" >
                <div className="cart-order-location d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ height: 24, width: 24 }}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                  </svg>
                </div>
                <div>
                  <h6 className="fs-18 fs-sm-14 fw-600 text-gray-600 m-0 pl-2 pl-sm-3">{cart_at_login?.address_line}</h6>
                </div>
              </li>}
              {/* <li className="d-flex align-items-center justify-content-start bg-danger" >
                <div className="cart-order-location d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{ height: 24, width: 24 }}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                  </svg>
                </div>
                <div>
                  <h6 className="fs-18 fs-sm-14 fw-600 text-gray-600 m-0 pl-2 pl-sm-3">{cart_at_login?.formatted_address}</h6>
                </div>
              </li> */}
              <li className="d-flex align-items-center justify-content-start pt-2">
                <div className="cart-order-location d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ height: 24, width: 24 }}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                  </svg>
                </div>
                {!isEmpty(cartDetails) && <h6 className="fs-18 fs-sm-14 fw-600 text-gray-600 m-0 pl-2 pl-sm-3">{getZonedTime(cartDetails?.deliveryDate, 'EEEE MMMM d, hh:mma, zzzz', cartDetails?.time_zone, 'insertAtInFormat')}</h6>}
              </li>
            </ul>
          </div>
          {/*Order page Deliver info form */}
          <hr style={{width:'50%'}}/>
          <div className="pt-0 pt-sm-0">
            <h6 className="fs-18 fs-sm-14 fw-600 text-gray-600 m-0">Delivery Details</h6>
            <form action="" className="p-3">
              <div>
                <label htmlFor="name" className="mb-1 text-gray-primary fw-normal fs-16 fs-sm-14">Name of Person</label>
                <input type="text" className="form-control" name="name" value={cartDeliveryDetails.name} onChange={(e: any) => cartDeliveryDetailsHandler(e)} id="" />
                {(isEmpty(cartDeliveryDetails?.name)) && <small className='invalid-feedback-password'> Name is required </small>}
              </div>
              <div className="pt-2">
                <PhoneInput
                  specialLabel='Phone Number'
                  country={'us'}
                  onlyCountries={['us']}
                  masks={{ us: '...-...-....' }}
                  disableDropdown={true}
                  placeholder={'XXX-XXX-XXXX'}
                  // countryCodeEditable={false}
                  value={cartDeliveryDetails.phoneNumber}
                  onChange={phone => setCartDeliveryDetails({ ...cartDeliveryDetails, phoneNumber: phone })}
                  disableCountryCode={true}
                  isValid={(value: any, country: any) => {
                    if (isEmpty(value)) {
                      setPhoneErr('empty')
                      // invalidphone()
                      return false
                    }
                    else if (!value.match(/^[0-9]{10}$/)) {
                      // invalidphone()
                      // invalidphone(value)
                      setPhoneErr('invalid')
                      return false
                    } else {
                      // cartDeliveryDetailsPhoneHandler(value)
                      setPhoneErr('valid')
                      // handle && handle(value)
                      return true
                    }
                  }}
                />
                {<small className='invalid-feedback-password'>
                  {errorStatefun()}
                </small>
                }
              </div>
              {/* <div>
                <label htmlFor="">Phone Number</label>
                <input type="text" className="form-control" name="" id="" />
              </div> */}
              <div>
                <label htmlFor="deliveryInstructions" className="pt-2 mb-1 text-gray-primary fw-normal fs-16 fs-sm-14">Delivery Instructions</label>
                <textarea
                  name="deliveryInstructions"
                  className="form-control"
                  placeholder='e.g. Call receptionist upon arrival, wait in the lobby, setup in conference room, etc'
                  value={cartDeliveryDetails.deliveryInstructions}
                  id=""
                  onChange={(e: any) => cartDeliveryDetailsHandler(e)}
                  cols={20} rows={3}
                />
              </div>
              {/* <h3>your input form and payment card is here</h3> */}
            </form>
            <hr style={{width:'50%'}}/>
            <div className="mt-2 p-1 pt-sm-3 px-sm-3 cart-cc rounded">
              <div>
                <StripeLayout
                  parentRef={this}
                  fromCart={true}
                  isLoading={false}
                  cartStripeTokenHandler={cartStripeTokenHandler}
                  prevPaymentDetails={defaultCreditCard}
                  orderPlaced={noPaymentOrderPlaced}
                  stripeError={stripeError}
                // previousMethodError={previousMethodError}
                />
              </div>
            </div>
            <hr style={{width:'50%'}}/>
            {<section className={`cart-summary-amounts-wrapper cart-checkout px-0 w-100 ${false ? 'cart-summary-amounts-wrapper-withoutshadow' : 'cart-summary-amounts-wrapper'} `} style={{ 'position': "sticky" }}>
              <Accordion expanded={expanded} onChange={handleCartSummary}
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                className="cart-accordian-wrapper"
              // onClick={prepareDeliveryFeePayload(cartItems)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: 'white', backgroundColor: '#6e6e73', borderRadius: '50%' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="py-1 accordian-summary"
                >

                  {/* cart-summary-total-amount*/}
                  <div
                    className="cart-summary-total-amount d-flex align-items-center justify-content-betweenmy-1 w-100"
                  // onClick={(() => prepareDeliveryFeePayload(cartItems))}
                  >
                    <h4 className="fw-600 fs-sm-16 m-0 text-gray-700">Total</h4>
                    {/* <h4 className="fw-600 ml-auto mr-3 mb-0 text-gray-700">{`$${(+cartSummary?.total ?? 0).toFixed(2)}`}</h4> */}
                    <h4 className="fw-600 fs-sm-16 ml-auto mr-3 mb-0 text-gray-700">{`$${isNaN(cartSummary?.total) ? '0.00' : cartSummary?.total ?? 0}`}</h4>
                  </div>

                </AccordionSummary>

                <AccordionDetails className="px-4 pt-3 pb-0">
                  <div>
                    <li className="d-flex align-items-center justify-content-between mb-2">
                      <p className="fs-18 fs-sm-14 fw-normal text-gray-600 m-0">Items Subtotal</p>
                      {/* <p className="fs-18 fw-normal text-gray-600 m-0">{`$${(+cartSummary?.itemsSubtotal ?? 0).toFixed(2)}`}</p> */}
                      <p className="fs-18 fs-sm-14 fw-normal text-gray-600 m-0">{`$${isNaN(+cartSummary?.itemsSubtotal) ? '0.00' : (+cartSummary?.itemsSubtotal ?? 0).toFixed(2)}`}</p>
                    </li>
                    {/* cart-summary-amounts-sales-tex-fee */}
                    <li className="cart-summary-amounts-sales-tex-fee d-flex align-items-center justify-content-between mt-1">
                      <p className="cart-summary-details-text">Sales Tax</p>
                      {/* <p className="cart-summary-details-text">{`$${(+cartSummary?.salesTax ?? 0).toFixed(2)}`}</p> */}
                      <p className="cart-summary-details-text">{`$${isNaN(+cartSummary?.salesTax) ? '0.00' : (+cartSummary?.salesTax ?? 0).toFixed(2)}`}</p>
                    </li>
                    {/* cart-summary-amounts-delivery-fee */}
                    <li className="cart-summary-amounts-delivery-fee d-flex align-items-center justify-content-between">
                      <p className="cart-summary-details-text">Delivery Fee</p>
                      {/* <p className="cart-summary-details-text">{`$${(+cartSummary?.deliveryFee ?? 0).toFixed(2)}`}</p> */}
                      <p className="cart-summary-details-text">{`$${isNaN(+cartSummary?.deliveryFee) ? '0.00' : (+cartSummary?.deliveryFee ?? 0).toFixed(2)}`}</p>
                    </li>
                    {/* cart-summary-card-processing-fee */}
                    <li className="cart-summary-card-processing-fee d-flex align-items-center justify-content-between">
                      <p className="cart-summary-details-text">Credit Card Processing Fee</p>
                      {/* <p className="cart-summary-details-text">{`$${(+cartSummary?.cardProcessingFee ?? 0).toFixed(2)}`}</p> */}
                      <p className="cart-summary-details-text">{`$${isNaN(+cartSummary?.cardProcessingFee) ? '0.00' : (+cartSummary?.cardProcessingFee ?? 0).toFixed(2)}`}</p>
                    </li>
                    {/* cart-summary-tip-amount */}
                    <li className="cart-summary-tip-amount d-flex align-items-center justify-content-between mt-3" style={{marginBottom:"12px"}}>
                      <p className="cart-summary-details-text">Driver Tip</p>
                      {/* <p className="cart-summary-details-text">10% ($2.5)</p> */}
                      <p className="cart-summary-details-text">
                        {/* {`${(cartSummary?.driverTipPercent && (+cartSummary?.driverTipPercent >0)) ?
                      cartSummary?.driverTipPercent+'%'
                        : ''} ($${(+cartSummary.driverTip ?? 0).toFixed(2)})
                    `} */}
                        {`${(cartSummary?.driverTipPercent && (!isEmpty(cartSummary) && cartSummary?.driverTipPercent! > 0)) ?
                          cartSummary?.driverTipPercent + '%'
                          : ''} ${!isEmpty(cartSummary) && cartSummary.driverTipPercent && cartSummary.driverTipPercent! > 0 ? 'is' : ''}  $${isNaN(+cartSummary.driverTip) ? '0.00' : (+cartSummary.driverTip ?? 0).toFixed(2)}
                    `}
                        {/* {`${(cartSummary?.driverTipPercent && (cartSummary?.driverTipPercent >0)) ?
                      cartSummary?.driverTipPercent+'%'
                        : ''} ($${isNaN(+cartSummary.driverTip) ? '0.00' :(+cartSummary.driverTip ?? 0).toFixed(2)})
                    `} */}
                      </p>
                      {/* <p className="cart-summary-details-text">{`${!isEmpty(tipLabel) ? tipLabel+'%' : ''} ($${cartSummary.driverTip ?? 0})`}</p> */}
                    </li>
                    {/* cart-summary-tip-amount-selection */}
                    {tipItnput ?
                      <li className="d-flex align-items-center justify-content-between">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text px-3" id="basic-addon1">$</span>
                          </div>
                          <input className="form-control mr-2 input-tip-calculation" type='number' placeholder="Enter tip amount" name="other_tip" value={tipOtherValue} onChange={onTipSelect} />
                        </div>
                        <button
                          className="btn btn-danger"
                          style={{ minWidth: '64px', height: '42px' }}
                          onClick={goToTipSelection}
                        >
                          Ok
                        </button>
                      </li>
                      :
                      <div className="cart-summary-tip-amount-selection">
                        <select
                          multiple
                          style={{ backgroundColor: '#F8F9FA' }}
                          className="form-control cart-summary-tip-selection p-0"
                          id="exampleFormControlSelect2"
                          onChange={onTipSelect}
                        >
                          {TipSelection?.map((opt, index) => <option key={index} value={opt.value} selected={cartSummary?.driverTipPercent === opt.value || (cartSummary?.driverTipPercent?.toString() === '' && opt.value === 'other')}>{opt.label}</option>)}
                        </select>
                      </div>
                    }
                  </div>
                </AccordionDetails>
              </Accordion>
              {/* Cart checkout button */}
              <footer className="mt-3 px-sm-0">
                {placeOrderLoading ?
                  <button className="btn btn-block btn-danger py-3 fs-18 fw-600 checkout-btn" disabled >
                    <span className='spinner-border' role='status' aria-hidden='true'></span>
                  </button>
                  :
                  <button
                    className="btn btn-block btn-danger py-3 fs-18 fw-600 checkout-btn"
                    // disabled={(!isEmpty(cardDetails) || cartStripeTokenLocal) ? false : true}
                    onClick={() => (!isEmpty(defaultCreditCard) || cartStripeTokenLocal) ? placeOrder() : noPreviousPaymentMethodHandler()}
                    disabled={wrongDeliveryAddress}
                  >Place Order</button>
                }
              </footer>
            </section>}
          </div>
          {/* <dl className="cart-summary-total-amount d-flex align-items-center justify-content-between mb-3 mt-4">
            <h4 className="fw-600 fs-sm-16 m-0 text-gray-700">Total</h4>
            <h4 className="fw-600 fs-sm-16 m-0 text-gray-700">{`$${isNaN(cartSummary?.total) ? '0.00' :(+cartSummary?.total ?? 0).toFixed(2)}`}</h4>
          </dl> */}
          {/* Cart Place order page footer */}
          {/* <footer className="mt-3 px-sm-0">
            {placeOrderLoading ?
              <button className="btn btn-block btn-danger py-3 fs-18 fw-600 checkout-btn" disabled >
                <span className='spinner-border' role='status' aria-hidden='true'></span>
              </button>
              :
              <button
                className="btn btn-block btn-danger py-3 fs-18 fw-600 checkout-btn"
                // disabled={(!isEmpty(cardDetails) || cartStripeTokenLocal) ? false : true}
                onClick={() => (!isEmpty(cardDetails) || cartStripeTokenLocal) ? placeOrder() : noPreviousPaymentMethodHandler()}
              >Place Order</button>
            }
          </footer> */}
        </section>
      </section>}
      {/* Cart Login page End here */}

      <Modal
        isOpen={checkoutPopUp || deletePopUP}
        onRequestClose={closeModal}
        style={deletePopUP ? deletePopUpStyles : customStyles}
      >
        {/* // Delete Modal */}
        {deletePopUP ?
          <div className='order-modal'>
            <div className='slide'>
              <div className='modal-content cursor-default p-32'>
                <div className="cart-delete-modal cart-delete-modal-body mx-auto">
                    <div className="mx-auto d-flex aling-items-center justify-content-center">
                      <div className="trash-bin-wrapper">
                        <i className="far fa-trash-alt"></i>
                      </div>
                    </div>
                    <h5 className="mb-0 fw-600 text-gray-primary text-center pt-3 ">Delete Item</h5>
                    <p className="fs-18 m-0 text-center" style={{color:'#4D5B60'}}>Are you sure you want to delete this Item?</p>
                    <div className="pt-4 d-flex justify-content-center align-items-center">
                      <button
                        onClick={() => setDeletePopUP(false)}
                        className="btn rounded-pill cart-item-keep-btn"
                      >
                        Keep It
                      </button>
                      <button
                        onClick={() => changeItemCount(deleteItemIndex as number, OPERATION.DELETE)}
                        className='btn rounded-pill cart-item-delete ml-3'
                      >
                        Yes, Delete
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        :
          <div className='order-modal'>
            <div className='slide'>
              <div className='modal-content cursor-default'>
                <div className='modal-header'>
                  <h3
                    id='modal-title'
                    className='modal-title'
                    style={{ fontWeight: 600, fontSize: 22 }}
                  >
                    Order Received
                  </h3>
                  <button type='button' onClick={closeModal} className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div>
                  <div id='order-status-update-popup' className='modal-body py-2'>
                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ minHeight: 266, gap: 20 }}>
                      <img width={150} height={150} src='/imgs/confirmation-tick.gif' alt='confirmation-tick' />
                      <p style={{ fontSize: 20, color: '#646464' }} className='text-center'>
                        You’re All Set!
                        <br />
                        Your order has been received!
                      </p>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      onClick={closeModal}
                      className='btn accept-order-btn border-0'
                      style={{ borderRadius: 22, backgroundColor: '#157FFC', padding: '12px 44px', color: 'white' }}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </Modal>

      <>
        {toggleAddressPopUp && <DeliveryAddressPopUp
          setAddressInMarketPlace={(address: number | AddressDataType) => () => { }}
          selectedCompanyAddress={undefined}
          // addresses={cartDetails?.deliveryAddress}
          isOpen={toggleAddressPopUp}
          closeModal={() => closeAddressPopUp()}
          fromCart={true}
          setNewAddressForCart={() => setNewAddressInCart()}
          cartAddress={'47 model town lahore'}
          openFilter={() => { }}
          fromPublicMarketPlace={false}
          handleDateChange={() => { }}
        // resetSearchBar={this.resetSearchBar}
        />}

        {false && <div className=' w-100 mt-4 address-popup-ove'>
          <div className="container px-0">
            {/* <div className="col-lg-12 w-100 position-relative address-popup-form px-0">
                        <div className="bg-white mb-1 mb-sm-2 px-4 py-3 mx-3 mx-sm-0 row address-popup-radious">
                          <div className='px-0 col-sm-12 col-md-12 col-lg-6 d-flex pb-1 pb-sm-0'>
                            <p className='mb-0 fw-normal delivery-lable py-2 d-none'>DELIVERING TO:</p>
                            <div className='main-custom-options main-custom-options-marketplace d-flex align-items-center'>
                              <span className='icons-wrapper mx-0 ml-md-0 mr-lg-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25.062 33">
                                  <path id="location-dot-thin_-_Outline" data-name="location-dot-thin - Outline" d="M12.031-.5A12.533,12.533,0,0,1,24.562,12.031c0,2.464-1.394,5.974-4.142,10.432a96.042,96.042,0,0,1-6.514,9.129,2.393,2.393,0,0,1-3.75,0C7.658,28.521-.5,18.023-.5,12.031A12.531,12.531,0,0,1,12.031-.5ZM14.473.76A11.533,11.533,0,0,0,.5,12.031c0,2.253,1.374,5.679,3.972,9.908a90.26,90.26,0,0,0,6.462,9.026,1.393,1.393,0,0,0,2.193,0c3.137-3.926,10.436-13.633,10.436-18.937a11.534,11.534,0,0,0-.26-2.442,11.635,11.635,0,0,1,.258,2.442,12.385,12.385,0,0,1-1.155,4.555,40.474,40.474,0,0,1-2.836,5.351,104.843,104.843,0,0,1-6.445,9.029,1.389,1.389,0,0,1-2.183,0A104.616,104.616,0,0,1,4.5,21.94a40.169,40.169,0,0,1-2.838-5.353A12.321,12.321,0,0,1,.5,12.031,11.529,11.529,0,0,1,12.031.5,11.635,11.635,0,0,1,14.473.76ZM12.031,30.5a.4.4,0,0,0,.313-.157,103.758,103.758,0,0,0,6.375-8.931A39.472,39.472,0,0,0,21.484,16.2a11.448,11.448,0,0,0,1.076-4.166,10.529,10.529,0,0,0-21.057,0A11.387,11.387,0,0,0,2.58,16.2a39.172,39.172,0,0,0,2.766,5.214,103.531,103.531,0,0,0,6.374,8.93A.4.4,0,0,0,12.031,30.5Zm0-23.979a5.513,5.513,0,1,1-5.513,5.513A5.519,5.519,0,0,1,12.031,6.518Zm0,10.026a4.513,4.513,0,1,0-4.513-4.513A4.518,4.518,0,0,0,12.031,16.544Zm0-9.023a4.51,4.51,0,1,1-4.51,4.51A4.515,4.515,0,0,1,12.031,7.521Zm0,8.021a3.51,3.51,0,1,0-3.51-3.51A3.514,3.514,0,0,0,12.031,15.542Z" transform="translate(0.5 0.5)" fill="#dc3644" />
                                </svg>
                              </span>
                              <input
                                type='text'
                                className='dropdown-menu-arrow marketplace-address-picker-input form-control p-0'
                                id='address'
                                name='selectedCompanyAddress'
                                placeholder='Add Delivery Address'
                                readOnly
                              />
                              <i className='fas fa-chevron-down mx-1 px-1' style={{ lineHeight: 'inherit' }}></i>
                            </div>
                          </div>
                          <div className="px-0 col-sm-12 col-md-12 col-lg-6 d-flex pb-1 pb-sm-0 mt-2 mt-lg-0">
                            <div className=" pt-2 pt-md-0 px-0 px-ml-3 d-flex align-items-center bg-white">
                              <div className='px-0 position-relative d-flex align-items-center'>
                                <span className='icons-wrapper mx-0 ml-md-0  mr-lg-2'>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 28.429">
                                    <path id="calendar-thin_-_Outline" data-name="calendar-thin - Outline" d="M6.429-.5a.93.93,0,0,1,.929.929v2.5h9.286V.429a.929.929,0,1,1,1.857,0v2.5h2.071A3.933,3.933,0,0,1,24.5,6.857V24a3.933,3.933,0,0,1-3.929,3.929H3.429A3.933,3.933,0,0,1-.5,24V6.857A3.933,3.933,0,0,1,3.429,2.929H5.5V.429A.93.93,0,0,1,6.429-.5ZM5.5,4.786H3.429A2.074,2.074,0,0,0,1.357,6.857V8.929H22.643V6.857a2.074,2.074,0,0,0-2.071-2.071H18.5V6.429a.929.929,0,1,1-1.857,0V4.786H7.357V6.429a.929.929,0,0,1-1.857,0Zm17.143,6H1.357V24a2.074,2.074,0,0,0,2.071,2.071H20.571A2.074,2.074,0,0,0,22.643,24Z" transform="translate(0.5 0.5)" fill="#dc3644" />
                                  </svg>
                                </span>
                                <input
                                  type='text'
                                  className='dropdown-menu-arrow marketplace-address-picker-input form-control p-0'
                                  id='address'
                                  name='selectedCompanyAddress'
                                  placeholder={dayjs().hour() >= 14 ? `${dayjs(`${dayjs(new Date()).add(2, 'day')}`).format('dddd, MMM D')} at 11:45 AM` : `${dayjs(`${dayjs(new Date()).add(1, 'day')}`).format('dddd, MMM D')} at 11:45 AM`}
                                  readOnly
                                />
                                <i className='fas fa-chevron-down mx-1' style={{ lineHeight: 'inherit' }}></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
            <div className="">
              <div className="bg-white address-form-popup-radious mx-3 mx-sm-0">
                <MarketPlaceDeliveryAddressPublicPopUp
                  isOpen={toggleAddressPopUp}
                  closeModal={() => closeAddressPopUp()}
                  fromPublicMarketPlace={false}
                />
              </div>
            </div>
          </div>
        </div>}
      </>
    </>

  );
}
export type ServerCartRef = React.ElementRef<typeof ServerCartDetails>;
export default ServerCartDetails